<template>
    <div class="page">
        <div class="title">定制合同列表</div>

        <el-divider></el-divider>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">搜索</div>
            </div>
            <div class="filter_btns">
                <!-- <div class="filter_btns_norm_button">
                    咨询顾问<span>（当前可用10次）</span>
                </div> -->
                <div
                    class="custom_button plain no_select"
                    @click="dialogFormVisible = true"
                >
                    创建合同模板
                </div>
                <!-- <div class="custom_button no_select">在线签署</div> -->
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="title" label="合同名称"></el-table-column>
            <el-table-column
                prop="template_id"
                label="合同模板编号"
                min-width="150"
            ></el-table-column>
            <el-table-column prop="add_date" label="创建时间" min-width="150"></el-table-column>
            <el-table-column prop="base_pay" label="在用人数"></el-table-column>
            <el-table-column prop="add_date" label="上传时间" min-width="150"></el-table-column>
            <el-table-column label="操作" min-width="120" fixed="right">
                <template v-slot:default="scope">
					<el-dropdown size="mini" split-button type="primary">
						操作
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="generateContract(scope.row)">生成合同</el-dropdown-item>
							<el-dropdown-item @click.native="openWindow(scope.row.contract_url)">查看模板</el-dropdown-item>
							<el-dropdown-item @click.native="downloadTemplate(scope.row.template_id)">下载模板</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
                    <!-- <div class="operations">
                        <div @click="generateContract(scope.row)">生成合同</div>
                        <div @click="openWindow(scope.row.contract_url)">
                            查看模板
                        </div>
                        <div @click="downloadTemplate(scope.row.template_id)">
                            下载模板
                        </div>
                    </div> -->
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>

        <el-dialog
            title="添加合同"
            width="40%"
            :visible.sync="dialogFormVisible"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="合同名称" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="合同上传：" prop="contractPdfUrl">
                    <input
                        type="file"
                        accept="application/pdf"
                        @change="pdfUpload"
                    />
                </el-form-item>
                <!-- <el-form-item label="模板字段" prop="doc_params">
                    <br />
                    <div v-for="(item, index) in form.doc_params" :key="index">
                        <el-col :span="22">
                            <el-input
                                v-model="form.doc_params[index]"
                            ></el-input>
                        </el-col>
                        <i
                            v-if="form.doc_params.length > 1"
                            class="el-icon-remove-outline"
                            style="
                                font-size: 30px;
                                color: #4db9d5;
                                line-height: 40px;
                            "
                            @click="removeParam(index)"
                        ></i>
                        <i
                            v-if="index === form.doc_params.length - 1"
                            class="el-icon-circle-plus-outline"
                            style="
                                font-size: 30px;
                                color: #409eff;
                                line-height: 40px;
                            "
                            @click="addParam(index)"
                        ></i>
                    </div>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="生成合同"
            width="40%"
            :visible.sync="dialogFormVisible2"
        >
            <el-form :model="gForm" ref="gForm" :rules="rules2">
                <el-form-item label="合同名称" prop="doc_title">
                    <el-input v-model="gForm.doc_title"></el-input>
                </el-form-item>

                <el-form-item label="签约对象：" prop="type">
                    <el-radio-group v-model="gForm.type">
                        <el-radio label="1" :disabled="user.is_store_son != 1"
                            >企业</el-radio
                        >
                        <el-radio label="2">员工</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item
                    label="选择员工"
                    prop="party_b_fdd_userid"
                    v-if="gForm.type == 2"
                >
                    <el-select
                        v-model="gForm.party_b_fdd_userid"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入员工姓名"
                        :remote-method="remoteMethod"
                        :loading="employee_loading"
                    >
                        <el-option
                            v-for="item in employee_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="选择企业"
                    prop="party_b_fdd_userid"
                    v-if="gForm.type == 1"
                >
                    <el-select
                        v-model="gForm.party_b_fdd_userid"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入企业名称"
                        :remote-method="remoteMethod2"
                        :loading="employee_loading"
                    >
                        <el-option
                            v-for="item in employee_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="合同上传：" prop="doc_url">
                    <input
                        type="file"
                        accept="application/pdf"
                        @change="genPdfUpload"
                    />
                </el-form-item>

                <!-- <el-form-item
                    :label="item"
                    :prop="item"
                    v-for="(item, index) in template_params"
                    :key="index"
                >
                    <el-input
                        v-model="gForm[template_params[index]]"
                    ></el-input>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="onGenSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
    data() {
        // const checkAllFill = (rule, value, callback) => {
        //     for (const item of value) {
        //         if (!item) {
        //             return callback(new Error("不能有字段为空"));
        //         }
        //     }
        //     callback();
        // };
        return {
            search: {
                keyword: "",
            },
            tableData: [],
            // 分页参数
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            employee_loading: false,
            dialogFormVisible: false,
            dialogFormVisible2: false,
            employee_options: [],
            template_params: [],
            form: {
                title: "",
                contractPdfUrl: "",
                // doc_params: [""],
            },
            gForm: {
                template_id: "",
                // parameter_map: "",
                doc_title: "",
                party_b_fdd_userid: "",
                type: "2",
                doc_url: "",
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: "请填写合同名称",
                        trigger: "blur",
                    },
                ],
                contractPdfUrl: [
                    {
                        required: true,
                        message: "请先上传合同",
                        trigger: "blur",
                    },
                ],
                // doc_params: [{ validator: checkAllFill, trigger: "blur" }],
            },
            rules2: {
                doc_title: [
                    {
                        required: true,
                        message: "请填写合同名称",
                        trigger: "blur",
                    },
                ],
                party_b_fdd_userid: [
                    {
                        required: true,
                        message: "该员工/企业未注册法大大",
                        trigger: "change",
                    },
                ],
                doc_url: [
                    {
                        required: true,
                        message: "请上传合同",
                        trigger: "change",
                    },
                ],
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    inject: ["reload"],
    created() {
        this.loadData = this.loadDataFactory();
        this.loadData();
    },
    computed: {
        ...mapGetters("users", {
            user: "user",
        }),
    },
    methods: {
        ...mapActions(["uploadFile"]),
        ...mapActions("contract", [
            "getCustomContractList",
            "addCustomContract",
            "getFddEmployeeList",
            "postGenerateContract",
            "getSubsidiaryList",
        ]),

        loadDataFactory(keyword = null) {
            let params = {};
            if (keyword) {
                params.keywords = keyword;
            }
            return () => {
                params.page = this.page.page;
                let loading = this.$loading();
                this.getCustomContractList(params)
                    .then((res) => {
                        console.log(res);
                        this.tableData = res.data.data.list;
                        this.page.total = res.data.data.total;
                        this.$nextTick(() => {
                            loading.close();
                        });
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                        this.$nextTick(() => {
                            loading.close();
                        });
                    });
            };
        },

        openWindow(url) {
            window.open(url, "_blank");
        },

        downloadTemplate(template_id) {
            this.openWindow(
                `${process.env.VUE_APP_BASE_API}/contract/fdd_template/download?template_id=${template_id}`
            );
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            // 生产带有搜索参数的函数
            this.loadData = this.loadDataFactory(this.search.keyword);
            this.loadData();
        },

        /**
         * 上传pdf
         */
        pdfUpload(file) {
            this.uploadFile(file.target.files[0])
                .then((res) => {
                    console.log(res);
                    this.form.contractPdfUrl = res.data.data.link;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        /**
         * 生成合同的上传pdf
         */
        genPdfUpload(file) {
            this.uploadFile(file.target.files[0])
                .then((res) => {
                    console.log(res);
                    this.gForm.doc_url = res.data.data.link;
                })
                .catch((err) => {
                    console.error(err);
                    this.$message.error("上传文件失败");
                });
        },

        /**
         * 删除表单字段
         */
        removeParam(index) {
            this.form.doc_params.splice(index, 1);
            this.form.doc_params = JSON.parse(
                JSON.stringify(this.form.doc_params)
            );
        },

        /**
         * 添加表单字段
         */
        addParam() {
            this.$set(this.form.doc_params, this.form.doc_params.length, "");
        },

        /**
         * 创建合同模板
         */
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.dialogFormVisible = false;
                    let loading = this.$loading();
                    // let temp_json = {};
                    // this.form.doc_params.forEach((item) => {
                    //     temp_json[item] = item;
                    // });
                    // this.form.template_params_json = JSON.stringify(temp_json);
                    console.log(this.form);
                    this.addCustomContract(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success(res.data.msg);
                            this.loadData();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },

        /**
         * 生成合同
         */
        generateContract(data) {
            console.log(data);
            this.gForm.template_id = data.template_id;
            this.gForm.doc_title = data.title;
            // this.gForm = { ...this.gForm, ...JSON.parse(data.template_params) };
            // this.template_params = Object.keys(
            //     JSON.parse(data.template_params)
            // );
            this.dialogFormVisible2 = true;
        },

        remoteMethod(query) {
            if (query !== "") {
                this.employee_loading = true;
                this.getFddEmployeeList({
                    page: 1,
                    keywords: query,
                })
                    .then((res) => {
                        this.employee_loading = false;
                        console.log(res);
                        this.employee_options = res.data.data.list.map(
                            (item) => {
                                return {
                                    label: item.real_name,
                                    value: item.fddUserInfo?.id,
                                };
                            }
                        );
                    })
                    .catch((e) => {
                        this.employee_loading = false;
                        console.error(e);
                    });
            } else {
                this.employee_options = [];
            }
        },

        remoteMethod2(query) {
            if (query !== "") {
                this.employee_loading = true;
                this.getSubsidiaryList({
                    page: 1,
                    keywords: query,
                })
                    .then((res) => {
                        this.employee_loading = false;
                        console.log(res);
                        this.employee_options = res.data.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.fddUserInfo?.id,
                            };
                        });
                    })
                    .catch((e) => {
                        this.employee_loading = false;
                        console.error(e);
                    });
            } else {
                this.employee_options = [];
            }
        },

        onGenSubmit() {
            this.$refs.gForm.validate((valid) => {
                if (valid) {
                    this.dialogFormVisible2 = false;
                    let loading = this.$loading();
                    // let temp_json = {};
                    // this.template_params.forEach((item) => {
                    //     temp_json[item] = this.gForm[item];
                    // });
                    // this.gForm.parameter_map = JSON.stringify(temp_json);
                    console.log(this.gForm);
                    this.postGenerateContract(this.gForm)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success(res.data.msg);
                            this.loadData();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }

        .filter_btns_norm_button {
            font-size: 12px;
            color: #4db9d5;
            cursor: pointer;

            > span {
                color: #1c2438;
            }
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
